import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { DefaultTFuncReturn } from 'i18next';
import { DatePicker, Space } from 'antd';
import type { DatePickerProps } from 'antd/es/date-picker';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

type FieldTypes = "text" | "select" | "selectwithfinder" | "textarea" | "datepicker" | "datetimepicker";

type OptionType = {
  value: string | number;
  label: string;
};

type FormFieldProps = {
  colspan?: number;
  label?: string | DefaultTFuncReturn;
  name: string;
  type?: string;
  customType?: FieldTypes;
  placeholder?: string;
  options?: OptionType[];
  children?: ReactNode;
};

export function FormField({ colspan, label, name, type = "text", customType = "text", placeholder, options, children }: FormFieldProps) {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const isError = meta.touched && meta.error;

  const inputClasses = isError
    ? "block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

  let enhancedChild;

  if (children) {
    const formElement = React.Children.only(children) as React.ReactElement;
    if (formElement && (formElement as React.ReactElement).type) {
      const elementType = (formElement as React.ReactElement).type;
      if (elementType === "input" || elementType === "select") {

        enhancedChild = React.cloneElement(formElement, {
          ...field,
          type: type,
          placeholder: placeholder,
          className: inputClasses
        });
      } else {
        enhancedChild = formElement;
      }
    }
  } else if (customType === "select" && options) {
    enhancedChild = (
      <select {...field} className={inputClasses}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  } else if (customType === "selectwithfinder" && options) {
    type OptionType = { value: string; label: string };

    const selectOptions: OptionType[] = options.map(option => ({ value: String(option.value), label: option.label }));
    const selectedOption: OptionType | null = selectOptions.find(option => option.value?.toString() === field.value?.toString()) || selectOptions[0] || null;
    const handleChange = (selectedOption: OptionType | null) => {
      field.onChange({ target: { name: field.name, value: selectedOption ? selectedOption.value : null } });
    };

    enhancedChild = (
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={selectOptions}
        classNamePrefix="react-select"
        placeholder=""
      />
    );
  } else if (customType === "textarea") {
    enhancedChild = (
      <textarea
        {...field}
        rows={3}
        placeholder={placeholder}
        className={inputClasses}
        value={field.value ? field.value : ""}
      />
    );
  } else if (customType === "datepicker") {
    const onChange = (
      value: DatePickerProps['value'],
    ) => {
      field.onChange({ target: { name: field.name, value: value ? dayjs(value).format('YYYY-MM-DD') : null } });
    };

    enhancedChild = (
      <DatePicker
        style={{ fontFamily: 'Inter var' }}
        className={inputClasses}
        format="DD-MM-YYYY"
        value={field.value ? dayjs(field.value) : null}
        onChange={onChange}
        placeholder={t("common.selectDate").toString()}
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            now: t("common.selectDateNow"),
            ok: t("common.selectDateConfirmation"),
          }
        }}
      />
    );
  } else if (customType === "datetimepicker") {
    const onChange = (
      value: DatePickerProps['value'],
    ) => {
      field.onChange({ target: { name: field.name, value: value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : null } });
    };

    enhancedChild = (
      <DatePicker
        style={{ fontFamily: 'Inter var' }}
        className={inputClasses}
        showTime={{ format: 'HH:mm:ss' }}
        format="DD-MM-YYYY HH:mm:ss"
        value={field.value ? dayjs(field.value) : null}
        onChange={onChange}
        placeholder={t("common.selectDate").toString()}
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            now: t("common.selectDateNow"),
            ok: t("common.selectDateConfirmation"),
          }
        }}
      />
    );
  } else {
    enhancedChild = (
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        className={inputClasses}
      />
    );
  }

  return (
    <div className={colspan ? `sm:col-span-${colspan}` : ""}>
      {label &&
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      }
      <div className={`relative ${label ? "mt-2 " : " "}rounded-md${children && React.Children.only(children) && (React.Children.only(children) as React.ReactElement).type ? " h-9" : " shadow-sm"}`}>
        {enhancedChild}
        {isError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {isError && <p className="mt-1.5 ml-1 text-xs text-red-600 italic" id={`${name}-error`}>{meta.error}</p>}
    </div>
  );
}