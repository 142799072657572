import { useState } from 'react';
import { useTranslation } from "react-i18next";
import SectionHeader from '../../components/common/header/SectionHeader';
import TreeView from '../../components/common/tree/TreeView';
import {
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import Alert from '../../components/common/alert/Alert';

export default function Documents() {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const root = {
    name: '/',
    children: [
      {
        name: 'Nominas',
        children: [
          {
            name: '2023',
            children: [
              {
                name: 'Enero',
                children: [
                  {
                    name: 'Nomina.pdf',
                    fileUrl: 'https://example.com/nomina-enero-2023.pdf',
                  },
                ],
              },
              {
                name: 'Febrero',
                children: [
                  {
                    name: 'Nomina.pdf',
                    fileUrl: 'https://example.com/nomina-febrero-2023.pdf',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Manuales',
        children: [
          {
            name: 'Control de presencia',
            children: [
              {
                name: 'Manual.pdf',
                fileUrl: 'https://example.com/manual.pdf',
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <>
      <SectionHeader title={t("documents.title")} textSize="text-3xl" />
      <Alert text='Esta pantalla es demostrativa (para mostrar lo que se podría hacer)' type='warning' />
      <h3 className="mt-6 text-base font-normal leading-6 text-gray-900 text-justify">
        {t("documents.subtitle")}
      </h3>
      <div className="min-h-screen py-6 flex justify-start items-start w-full">
        <div className="w-full col-span-full">
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              placeholder={t("documents.searchDocuments") ?? ""}
              autoComplete="street-address"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>
          <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg w-full">
            <div className="p-6 bg-white border-b border-gray-200">
              <TreeView root={root} searchValue={searchValue} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
