import { GetCookie } from "../../../services/cookie/CookieService";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export enum TabPermissionType {
    OnlyActiveUsers = "onlyActiveUsers",
    OnlyInactiveUsers = "onlyInactiveUsers",
}

export default function Tabs({ setTab, activeTab, tabs, section }: {
    setTab: (tabKey: string) => void,
    activeTab: string,
    tabs: Array<{ key: string, name: string, icon: any, current: boolean, permission?: string[] }>,
    section?: string
}) {
    const userPermissions = (JSON.parse(GetCookie("user") || "{}")).permissions;
    const userIsActive = (JSON.parse(GetCookie("user") || "{}")).isActive;

    function hasPermission(userPermissions: any, tabPermission: string[] | undefined, sectionPath?: string): boolean {
        if (!tabPermission) {
            return true;
        } else if (!userPermissions) {
            return false;
        } else if (tabPermission[0] === TabPermissionType.OnlyActiveUsers && !userIsActive) {
            return false;
        } else if (tabPermission[0] === TabPermissionType.OnlyInactiveUsers && userIsActive) {
            return false;
        }

        if (!sectionPath) {
            return true;
        }

        const sections = sectionPath.split("__");
        let currentPermissions = userPermissions;
        if (!currentPermissions) return false;
        for (const section of sections) {
            currentPermissions = currentPermissions[section];
            if (!currentPermissions) {
                return false;
            }
        }

        if (Array.isArray(currentPermissions)) {
            return tabPermission.some(permission => currentPermissions.includes(permission));
        } else {
            // Si no es un arreglo, asumir que el usuario no tiene permiso
            return false;
        }
    }

    return (
        <div>
            {/* <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select a tab</label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    defaultValue={tabs?.find((tab) => tab.name === activeTab)?.name}
                >
                    {tabs.map((tab) => (
                        <option key={tab.key}>{tab.name}</option>
                    ))}
                </select>
            </div> */}

            {/* <div className="hidden sm:block"> */}
            <div>
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => {
                            if (!hasPermission(userPermissions, tab.permission, section)) {
                                return null;
                            }
                            const isCurrentTab = tab.key === activeTab;

                            return (
                                <a
                                    key={tab.key}
                                    onClick={() => setTab(tab.key)}
                                    className={classNames(
                                        isCurrentTab ? 'border-indigo-500 text-indigo-600 cursor-pointer'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer',
                                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                                    )}
                                    aria-current={isCurrentTab ? 'page' : undefined}
                                >
                                    <tab.icon
                                        className={classNames(
                                            isCurrentTab ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-5 w-5'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{tab.name}</span>
                                </a>
                            );
                        })}
                    </nav>
                </div>
            </div>
        </div>
    )
}
