import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { CloseWorkShift } from "../../services/time-control/TimeControlService";
import { WorkShift } from '../../interfaces/time-control/WorkShift';
import UploadFiles from '../common/upload/UploadFiles';
import { WorkShiftAttachment } from '../../interfaces/time-control/WorkShiftAttachment';
import { Modal } from 'antd';

interface WorkShiftProps {
    workShift: WorkShift;
    onUpdateParent: () => void;
    isLoading: (state: boolean) => void;
    closeModal: () => void;
}

export default function WorkShiftEnd(props: WorkShiftProps) {
    const { t, i18n } = useTranslation();
    const [workShift, setWorkShift] = useState<WorkShift>(props.workShift);

    const handleButtonClickWorkShiftFinish = async () => {
        props.isLoading(true);
        if (await CloseWorkShift(workShift)) {
            Modal.success({
                title: t("workShift.endSuccessfulTitle"),
                content: t("workShift.endSuccessfulSubtitle"),
                centered: true,
                width: 600,
            })
            props.onUpdateParent();
            props.closeModal();
        }
        props.isLoading(false);
    };

    const updateWorkShiftAttachments = (selectedFiles: File[]) => {
        if (selectedFiles.length > 0) {
            const newAttachments = selectedFiles.map((file, index) => {
                const attachment: WorkShiftAttachment = {
                    id: index,
                    fileName: file.name,
                    fileType: file.type,
                    attachment: file
                };
                return attachment;
            });

            setWorkShift(prevWorkShift => {
                const existingAttachments = prevWorkShift.attachments ?? [];
                const existingFileNames = existingAttachments.map(a => a.fileName);

                const nonDuplicateAttachments = newAttachments.filter(
                    newAttachment => !existingFileNames.includes(newAttachment.fileName)
                );

                return {
                    ...prevWorkShift,
                    attachments: [...existingAttachments, ...nonDuplicateAttachments]
                };
            });
        } else {
            setWorkShift(prevWorkShift => ({
                ...prevWorkShift,
                attachments: []
            }));
        }
    };

    return (
        <div>
            <div className="col-span-full">
                <label htmlFor="cover-photo" className="block text-xl font-medium leading-6 text-gray-900">
                    {t("workShift.finishWorkShift")}
                </label>
                <div className="mt-6 space-y-4">
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("workShift.workShiftRemarksAndAttachmentsTitle")}
                    </label>
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                        onChange={(event) => {
                            setWorkShift(prevWorkShift => ({
                                ...prevWorkShift,
                                employeeRemarks: event.target.value
                            }));
                        }}
                    />
                </div>
                <div className="mt-6 space-y-4">
                    {/* {workShift.attachments !== undefined && workShift.attachments.length > 0 ? (
                        <div className="rounded-md bg-gray-50 px-6">
                            <dl className="grid grid-cols-1 sm:grid-cols-2">
                                <div className="border-t border-gray-300 px-0 py-4 sm:col-span-2">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">{t("workTime.attachments")}</dt>
                                    <dd className="mt-4 text-sm text-gray-900">
                                        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                            {workShift.attachments?.map((attachment, index) => (
                                                <li key={index} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                    <div className="flex w-0 flex-1 items-center">
                                                        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                            <span className="truncate font-medium">{attachment.fileName}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    ) : (
                        <>
                            <UploadFiles onUpdateParent={updateWorkShiftAttachments} />
                        </>
                    )} */}
                    <UploadFiles onUpdateParent={updateWorkShiftAttachments} />
                </div>
                <div className="mt-8 flex flex-col sm:flex-row items-center justify-end gap-x-6 gap-y-4 sm:gap-y-0">
                    <button
                        type="button"
                        className="w-full sm:w-fit inline-flex items-center rounded-md bg-gray-200 px-3.5 py-4 sm:py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300"
                        onClick={() => props.closeModal()}
                    >
                        {t("common.cancel")}
                    </button>
                    <button
                        type="button"
                        className="w-full sm:w-fit inline-flex items-center rounded-md bg-indigo-50 px-3.5 py-4 sm:py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                        onClick={() => handleButtonClickWorkShiftFinish()}
                    >
                        {t("workShift.finishWorkShiftButtonConfirmation")}
                    </button>
                </div>
            </div>
        </div>
    )
}