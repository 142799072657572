import './App.css';
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/authentication/Login";
import Dashboard from "./pages/menu/Dashboard";
import Notification from "./components/common/Notification";
import { GetCookie } from "./services/cookie/CookieService";
import Forgotten from "./pages/authentication/Forgotten";
import NotFound from './pages/not-found/NotFound';
import { ContextProvider } from './contexts/ContextProvider';

export interface AuthProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

function App() {
  const [notificationState, setNotificationState] = useState({
    visible: false,
    color: "green",
    messageTitle: "Default title",
    messageText: "Default text",
  });

  const resetNotificationState = () => {
    setNotificationState({
      visible: false,
      color: "",
      messageTitle: "",
      messageText: "",
    });
  };

  const showNotification = ({ color, messageTitle, messageText }: {
    color: string;
    messageTitle: string;
    messageText: string;
  }) => {
    resetNotificationState();
    setTimeout(() => {
      setNotificationState({
        visible: true,
        color: color,
        messageTitle: messageTitle,
        messageText: messageText,
      });
    }, 0);
  };

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const token = GetCookie("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  if (isLoggedIn === undefined) {
    // Muestra un componente de carga mientras se verifica la cookie
    return <div>Cargando...</div>;
  }

  return (
    <ContextProvider>
      <Router>
        <Routes>
          {isLoggedIn ? (
            <>
              <Route path="/*" element={<Dashboard setIsLoggedIn={setIsLoggedIn} />} />
            </>
          ) : (
            <>
              <Route path="/*" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/forgotten" element={<Forgotten />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Notification />
      </Router>
    </ContextProvider>
  );
}

export default App;