import React, { Fragment, FC } from 'react';
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export interface IDropdownItem {
    label: string;
    icon: any;
    action: () => void;
}

export interface IDropdownBlock {
    items: IDropdownItem[];
}

type DropdownProps = {
    title: string;
    blocks: IDropdownBlock[];
};

const Dropdown: FC<DropdownProps> = ({ title, blocks }) => {
    const renderMenuItem = (item: IDropdownItem, index: number) => (
        <Menu.Item key={index}>
            {({ active }) => (
                <button
                    onClick={item.action}
                    className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center w-full px-4 py-2 text-sm'
                    )}
                    style={{ textAlign: 'left' }}
                >
                    <item.icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    {item.label}
                </button>
            )}
        </Menu.Item>
    );

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {title}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                    {blocks.map((block, blockIndex) => (
                        <div className="py-1" key={blockIndex}>
                            {block.items.map((item, index) => renderMenuItem(item, index))}
                        </div>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default Dropdown;