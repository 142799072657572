import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { Formik, FormikValues } from 'formik';

type FormProps = {
    initialValues: FormikValues;
    validationSchema: any;
    children: React.ReactNode;
};

const Form = forwardRef((props: FormProps, ref) => {
    const { initialValues, validationSchema, children } = props;

    const formikRef = useRef<any>();

    useImperativeHandle(ref, () => ({
        validateForm: () => formikRef.current?.validateForm(),
        submitForm: () => {
            formikRef.current?.submitForm();
            return formikRef.current?.values;
        },
        setValues: () => formikRef.current?.setValues(),
        values: formikRef.current?.values
    }));

    return (
        <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={() => { }}
        >
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    {children}
                </form>
            )}
        </Formik>
    );
});

export default Form;