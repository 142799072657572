import { createContext, useContext, useState, useEffect, ReactNode, FC, SetStateAction, Dispatch } from 'react';
import Spinner from '../components/common/loader/Spinner';

interface SpinnerContextProps {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setShouldDelay: Dispatch<SetStateAction<boolean>>;
}

const SpinnerContext = createContext<SpinnerContextProps | undefined>(undefined);

interface SpinnerProviderProps {
    children: ReactNode;
}

export const SpinnerProvider: FC<SpinnerProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldDelay, setShouldDelay] = useState<boolean>(true);
    const [delayedLoading, setDelayedLoading] = useState<boolean>(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (isLoading) {
            if (shouldDelay) {
                timerId = setTimeout(() => setDelayedLoading(true), 500);
            } else {
                setDelayedLoading(true);
            }
        } else {
            setDelayedLoading(false);
        }
        return () => clearTimeout(timerId);
    }, [isLoading, shouldDelay]);

    return (
        <SpinnerContext.Provider value={{ isLoading: delayedLoading, setIsLoading, setShouldDelay }}>
            {children}
            {delayedLoading && <Spinner />}
        </SpinnerContext.Provider>
    );
};

export const useSpinner = (initial?: boolean): SpinnerContextProps => {
    const context = useContext(SpinnerContext);
    if (!context) {
        throw new Error('useSpinner must be used within a SpinnerProvider');
    }

    useEffect(() => {
        if (initial !== undefined) {
            context.setShouldDelay(!initial);
            context.setIsLoading(initial);
        }

        return () => {
            if (initial !== undefined) {
                context.setIsLoading(false);
            }
        };
    }, [initial]);

    return context;
};