import { Authentication } from "../../interfaces/authentication/Authentication";
import { AddAuthenticationCookies } from "../../services/cookie/CookieService";
import axios from 'axios';

const apiDomain = process.env.REACT_APP_API_AUTH_DOMAIN;

export async function LoginUser(
  username: string,
  password: string,
  applicationName: string = ""
): Promise<Authentication | null> {
  try {
    const response = await axios.post(`${apiDomain}/api/auth/login`, {
      username,
      password,
      applicationName
    });

    if (response.status !== 200) {
      return null;
    }

    const jsonResponse = response.data;
    const authentication: Authentication = {
      token: jsonResponse.token,
      user: {
        id: jsonResponse.user.id,
        username: jsonResponse.user.username,
        // language: jsonResponse.user.language,
        permissions: jsonResponse.user.permissions,
        isActive: jsonResponse.user.isActive,
      },
      employee: {
        id: jsonResponse.employee.id,
        documentType: jsonResponse.employee.documentType,
        documentNumber: jsonResponse.employee.documentNumber,
        firstName: jsonResponse.employee.firstName,
        lastName1: jsonResponse.employee.lastName1,
        lastName2: jsonResponse.employee.lastName2,
        workCenterId: jsonResponse.employee.workCenterId,
        workCenter: jsonResponse.employee.workCenter,
        jobId: jsonResponse.employee.jobId,
      }
    };

    AddAuthenticationCookies(authentication.token, JSON.stringify(authentication.user), JSON.stringify(authentication.employee));

    return authentication;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    return null;
  }
}

export async function RecoverPassword(
  email: string
): Promise<boolean> {
  try {
    // const response = await axios.post(`${apiDomain}/api/auth/login`, {
    //   username,
    //   password,
    //   applicationName
    // });

    // const jsonResponse = response.data;

    return true;
  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
  return false;
}