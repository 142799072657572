import { useTranslation } from "react-i18next";
import { PlusIcon, RocketLaunchIcon } from '@heroicons/react/20/solid'
import { OpenWorkShift } from "../../services/time-control/TimeControlService";
import { useSpinner } from '../../contexts/SpinnerContext';
import { Modal } from 'antd';

interface Props {
    onUpdateParent: () => void;
}

const WorkShiftStart: React.FC<Props> = ({ onUpdateParent }) => {
    const { t } = useTranslation();
    const { setIsLoading } = useSpinner();

    const openWorkShift = async () => {
        setIsLoading(true);
        await OpenWorkShift().then((result) => {
            if (result) {
                Modal.success({
                    title: t("workShift.startSuccessfulTitle"),
                    content: t("workShift.startSuccessfulSubtitle"),
                    centered: true,
                    width: 600,
                })
                onUpdateParent();
            }
        });
        setIsLoading(false);
    };

    return (
        <>
            <div className="my-16 text-center">
                <RocketLaunchIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <h3 className="mt-2 text-base font-semibold text-gray-900">{t("workShift.title")}</h3>
                <p className="mt-1 text-sm text-gray-500">{t("workShift.subtitle")}</p>
                <div className="mt-6">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-3.5 md:py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={openWorkShift}
                    >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        {t("workShift.startWorkShift")}
                    </button>
                </div>
            </div>
        </>
    )
}

export default WorkShiftStart;