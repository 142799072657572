import { useState, useEffect } from 'react';
import { User } from '../../interfaces/user/User';
import { GetUser } from '../../services/user/UserService';

export const UseUser = (userId: number) => {
    const [data, setData] = useState<User | null>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetUser(userId);
                setData(result);
            } catch (err) {
                console.log(err as Error);
            }
        };

        fetchData();
    }, []);

    return data;
};