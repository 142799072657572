import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Alert from '../alert/Alert';
import { PhotoIcon, XMarkIcon } from "@heroicons/react/20/solid";

interface UploadFilesProps {
    onUpdateParent: (selectedFiles: File[]) => void;
}

export default function UploadFiles(props: UploadFilesProps) {
    const { t, i18n } = useTranslation();
    const [fileError, setFileError] = useState<string | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [selectedFilesFileNames, setSelectedFilesFileNames] = useState<string[]>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && validateFile(file)) {
            setSelectedFiles([...selectedFiles, file]);
            setSelectedFilesFileNames([...selectedFilesFileNames, file.name]);
        }
    };

    const handleFileDelete = (index: number) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        const newFileNames = [...selectedFilesFileNames];
        newFileNames.splice(index, 1);
        setSelectedFilesFileNames(newFileNames);
    };

    const validateFile = (file: File): boolean => {
        const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'docx'];
        const maxFileSize = 10 * 1024 * 1024; // 10 MB

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (!allowedExtensions.includes(fileExtension || '')) {
            setFileError(t('uploadFiles.attachDocumentModalInvalidExtensionError'));
            return false;
        }

        if (file.size > maxFileSize) {
            setFileError(t('uploadFiles.attachDocumentModalMaxSizeError'));
            return false;
        }

        setFileError(null);
        return true;
    };

    useEffect(() => {
        props.onUpdateParent(selectedFiles);
    }, [selectedFiles]);

    return (
        <>
            <div className="mb-6">
                {selectedFilesFileNames && selectedFilesFileNames.length > 0 ? (
                    <>
                        <label htmlFor="comment" className="py-2 block text-base font-medium leading-6 text-gray-900">
                            {t("uploadFiles.selectedFiles")}
                        </label>
                        {selectedFilesFileNames.map((fileName, index) => (
                            <div key={index} className="flex items-center justify-between py-2">
                                <div className="flex items-center">
                                    <button onClick={() => handleFileDelete(index)} className="focus:outline-none">
                                        <XMarkIcon className="h-5 w-5 text-red-500 mr-3" />
                                    </button>
                                    <span className="block text-sm font-medium leading-6 text-gray-900 italic">{fileName}</span>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <label htmlFor="comment" className="py-2 block text-sm font-medium leading-6 text-gray-900 italic">
                        {t("uploadFiles.noFilesSelected")}
                    </label>
                )}
            </div>
            {fileError && <Alert text={fileError} type="error" />}
            <div className="mt-4 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                            <span>{t("uploadFiles.attachDocumentModalText1")}</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={handleFileChange}
                            />
                        </label>
                        <p className="pl-1">{t("uploadFiles.attachDocumentModalText2")}</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">{t("uploadFiles.attachDocumentModalText3")}</p>
                </div>
            </div>
        </>
    )
}