import React from 'react';

interface EnvironmentAlertProps {
    message: string | undefined;
}

const EnvironmentAlert = ({ message }: EnvironmentAlertProps) => {

    if (!message) {
        return null;
    }

    return (
        <div className="border-yellow-400 bg-yellow-50 text-yellow-800" style={{ padding: '15px' }}>
            <p className="text-sm italic text-justify" dangerouslySetInnerHTML={{ __html: message }}></p>
        </div>
    );
};

export default EnvironmentAlert;