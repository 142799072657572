import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./en.json";
import esTranslation from "./es.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            es: {
                translation: esTranslation,
            },
        },
        supportedLngs: ["en", "es"],
        fallbackLng: "es",
        detection: {
            order: ["path", "cookie", "localStorage", "navigator", "htmlTag", "querystring"],
            caches: ["cookie", "localStorage"]
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;