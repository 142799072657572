import axios from 'axios';
import { GetCookie, RemoveLoginCookies } from "../../services/cookie/CookieService";
import { Modal } from 'antd';

const apiDomain = process.env.REACT_APP_API_RRHH_EMPLOYEE_DOMAIN;
let isShowingConnectionErrorModal = false;

export const axiosRRHHEmployeeAPI = axios.create({
    baseURL: apiDomain,
    headers: {
        "Content-Type": "application/json",
    }
});

axiosRRHHEmployeeAPI.interceptors.request.use(
    (config) => {
        const token = GetCookie("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            delete config.headers.Authorization;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosRRHHEmployeeAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!error.response) {
            if (!isShowingConnectionErrorModal) {
                isShowingConnectionErrorModal = true;
                Modal.error({
                    title: "Problema de conectividad a Internet",
                    content: "Parece que no hay conexión a Internet o la cobertura es muy débil. Por favor, verifique su conexión y vuelva a intentarlo.",
                    centered: true,
                    width: 600,
                    onOk() {
                        isShowingConnectionErrorModal = false;
                    }
                });
            }

            return Promise.reject(error);
        }

        if (error.response && error.response.status === 400) {
            const errorData = error.response?.data;
            if (errorData && 'title' in errorData && 'message' in errorData) {
                Modal.warning({
                    title: errorData.title,
                    content: errorData.message,
                    centered: true,
                    width: 600
                });
            }
            return error.response;
        }
        else if (error.response && error.response.status === 401) {
            // Token caducado
            RemoveLoginCookies();
            window.location.href = '/home?expired_token=1';
            return;
        }
        else if (error.response && error.response.status === 500) {
            Modal.error({
                title: "Se ha producido un error",
                content: "Se ha producido un error, por favor, inténtelo de nuevo un poco más tarde. Si el problema persiste, contacte con el dpto. de Informática",
                centered: true,
                width: 600
            });
            return;
        }

        return Promise.reject(error);
    }
);

export default axiosRRHHEmployeeAPI;