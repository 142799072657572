import { DefaultTFuncReturn } from 'i18next';

type FormFieldReadOnlyProps = {
  colspan?: number;
  label?: string | DefaultTFuncReturn;
  value: string | undefined;
};

export function FormFieldReadOnly({ colspan, label, value }: FormFieldReadOnlyProps) {
  return (
    <div className={colspan ? `sm:col-span-${colspan}` : ""}>
      {label &&
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      }
      <div className={`relative ${label ? "mt-2 " : " "}`}>
        <div className="flex items-center justify-start gap-x-2 h-full">
          <div className="block py-1.5">{value || ""}</div>
        </div>
      </div>
    </div>
  );
}