import React, { useState, useEffect } from 'react';

export interface TreeNodeProps {
    node: {
        name: string,
        children?: TreeNodeProps['node'][],
        fileUrl?: string,
    },
    searchValue: string,
}

const TreeNode: React.FC<TreeNodeProps> = ({ node, searchValue }) => {
    const isFile = !!node.fileUrl;
    const [isOpen, setIsOpen] = useState(true);

    const hasSearchValue = node.name.toLowerCase().includes(searchValue.toLowerCase());

    useEffect(() => {
        if (hasSearchValue) setIsOpen(true);
    }, [hasSearchValue]);

    if (!hasSearchValue && isFile) return null;

    return (
        <div className={isFile ? "pl-10" : "pl-5"}>
            {isFile ? (
                // File
                <div className="my-1 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-blue-500">
                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V7.414A2 2 0 0016.414 6L13 2.586A2 2 0 0011.586 2H4zm5 7a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm1-5.5V4l3 3h-3z" clipRule="evenodd" />
                    </svg>
                    <a href={node.fileUrl} className="ml-2 text-blue-500 hover:underline">{node.name}</a>
                </div>
            ) : (
                // Folder
                <div className="my-1 text-gray-800">
                    <div className="flex items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 text-yellow-500">
                                <path d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 00-3-3h-3.879a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H6a3 3 0 00-3 3v3.162A3.756 3.756 0 014.094 9h15.812zM4.094 10.5a2.25 2.25 0 00-2.227 2.568l.857 6A2.25 2.25 0 004.951 21H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-2.227-2.568H4.094z" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v4a2 2 0 00 1 1.732V15a2 2 0 002 2h12a2 2 0 002-2V8.732a2 2 0 001-1.732V5a2 2 0 00-2-2H4zm2 5a2 2 0 11-4 0 2 2 0 014 0z" clipRule="evenodd" />
                            </svg>
                        }
                        <span className="ml-2 font-semibold">{node.name}</span>
                    </div>
                    {isOpen && node.children && node.children.map(childNode => (
                        <TreeNode key={childNode.name} node={childNode} searchValue={searchValue} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeNode;
