import { useState, useContext } from "react";
import { NotificationContext } from '../../contexts/NotificationContext';
import { RecoverPassword } from "../../services/authentication/AuthenticationService";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ModalSingleAction from "../../components/common/overlay/ModalSingleAction";
import { useSpinner } from "../../contexts/SpinnerContext";
import { getImagePath } from "../../resources/utils/common";

export default function Forgotten() {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useSpinner();
  const { showNotification } = useContext(NotificationContext);

  const [email, setEmail] = useState("");
  const [recoveryState, setRecoveryState] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setRecoveryState(false);

    if (email === "") {
      let requiredFields = t("common.errorDataRequired") + t("forgotten.email");
      showNotification({ color: "orange", messageTitle: t("common.errorDataValidation"), messageText: requiredFields});
    } else {
      setIsLoading(true);
      setRecoveryState(await RecoverPassword(email));
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex h-full-vh">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src={getImagePath("images/logo-ocon-black.webp")} className="" alt="logo" />
              <h2 className="mt-6 text-2xl font-bold italic tracking-tight text-gray-900 text-center">
                {t("login.employeePortalTitle")}
              </h2>
              <h2 className="mt-6 text-lg font-bold italic tracking-tight text-gray-900 text-center">
                {t("forgotten.title")}
              </h2>
              <h2 className="mt-6 text-base text-gray-900 text-justify">
                {t("forgotten.subtitle")}
              </h2>
            </div>
            <div className="mt-4">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("forgotten.email")}
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-2 gap-4">
                  <Link
                    to="/"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#8e98a4] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] hover:bg-[#697482]"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clipRule="evenodd" />
                    </svg>
                    <span className="text-sm font-semibold leading-6">
                      {t("common.cancel")}
                    </span>
                  </Link>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0] hover:bg-[#1679bb]"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                      <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                    </svg>
                    <span className="text-sm font-semibold leading-6">
                      {t("forgotten.recover")}
                    </span>
                  </button>
                </div>
              </form>
            </div>
            <ModalSingleAction isOpen={recoveryState} title={t("forgotten.title")} subtitle={t("forgotten.confirmation")} buttonText={t("common.understood")} buttonRedirection="/" />
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={getImagePath("images/login-background.webp")}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
