export interface ILocation {
    userDenyLocation: boolean
    latitude: string;
    longitude: string;
}

export const GetLocation = (): Promise<ILocation> => {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            reject(new Error('Geolocation is not supported by the browser'));
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        userDenyLocation: false,
                        latitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString(),
                    });
                },
                (error) => {
                    if (error.code === 1) {
                        resolve({
                            userDenyLocation: true,
                            latitude: "DENIED",
                            longitude: "DENIED",
                        });
                    } else {
                        reject(error);
                    }
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                }
            );
        }
    });
};