import { SectionHeaderProps } from '../../../interfaces/common/header/SectionHeaderProps';

export default function SimpleHeader({
    title,
    textSize
}: SectionHeaderProps) {
    return (
        <div className="border-b border-gray-200 pb-5">
            <h3 className={`${textSize} font-semibold leading-6 text-gray-900`}>{title}</h3>
        </div>
    )
}