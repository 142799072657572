import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { Formik } from 'formik';

type FormProps = {
    children: React.ReactNode;
};

const FormReadOnly = forwardRef((props: FormProps, ref) => {
    const { children } = props;

    const formikRef = useRef<any>();

    useImperativeHandle(ref, () => ({
        validateForm: () => formikRef.current?.validateForm(),
        submitForm: () => {
            formikRef.current?.submitForm();
            return formikRef.current?.values;
        },
        setValues: () => formikRef.current?.setValues(),
        values: formikRef.current?.values
    }));

    return (
        <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={() => { }}
        >
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    {children}
                </form>
            )}
        </Formik>
    );
});

export default FormReadOnly;