import { FC } from 'react';

const Spinner: FC = () => {
    return (
        <div className="fixed inset-0 flex justify-center items-center z-[2001]">
            <div className="absolute inset-0 bg-gray-800 opacity-25"></div>
            <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
    );
};

export default Spinner;