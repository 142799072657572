import { WorkShift } from "../../interfaces/time-control/WorkShift";
import { Employee } from "../../interfaces/employee/Employee";
import { GetCookie } from "../../services/cookie/CookieService";
import { axiosRRHHEmployeeAPI } from '../axios/AxiosRRHHEmployeeAPI';
import { WorkTime as IWorkTime } from "../../interfaces/time-control/WorkTime";
import { TimeLogType, TimeLogTypeCategory } from "../../interfaces/time-control/TimeLogType";
import { GetLocation } from "../location/LocationService";
import { WorkShiftAttachment } from "../../interfaces/time-control/WorkShiftAttachment";

export async function UpdateWorkShift(
    workShift: WorkShift
): Promise<WorkShift> {
    try {
        const response = await axiosRRHHEmployeeAPI.put("/api/timecontrol/WorkShift", workShift);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return workShift;
}

export async function PatchWorkShift(
    id: number,
    updates: { fieldName: string, fieldValue: any }[]
): Promise<WorkShift | null> {
    try {
        const patchWorkShift = updates.map(update => {
            return { op: "replace", path: `/${update.fieldName}`, value: update.fieldValue };
        });

        const response = await axiosRRHHEmployeeAPI.patch(`/api/timecontrol/WorkShift/${id}`, patchWorkShift);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function OpenWorkShift(): Promise<boolean> {
    const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
    const location = await GetLocation();
    const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/OpenWorkShift", {
        employeeId: employee.id,
        timeLogTypeId: 1,
        location: location
    });

    return response.status === 200;
}

export async function CloseWorkShift(
    workShift: WorkShift
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const location = await GetLocation();
        const formData = new FormData();

        if (workShift.attachments) {
            workShift.attachments.forEach((attachment, index) => {
                formData.append("files", attachment.attachment, attachment.fileName);
            });
        }

        if (workShift.employeeRemarks) {
            formData.append("employeeRemarks", workShift.employeeRemarks);
        }

        formData.append("locationDTO", JSON.stringify(location));

        const response = await axiosRRHHEmployeeAPI.post(
            `/api/timecontrol/CloseWorkShift/${workShift.id}/${employee.id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function GetCurrentWorkShiftByEmployee(
    id?: number
): Promise<WorkShift | null> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetCurrentWorkShiftByEmployee", {
            employeeId: id ?? employee.id,
        });

        if (response.status !== 200) {
            return null;
        }

        const jsonResponse = response.data;
        const workShift: WorkShift = {
            id: jsonResponse.id,
            employeeId: jsonResponse.employeeId,
            startShift: jsonResponse.startShift,
            endShift: jsonResponse.endShift,
            isActive: jsonResponse.isActive,
            employeeRemarks: jsonResponse.employeeRemarks,
            totalWorkTime: jsonResponse.totalWorkTime,
            workTimes: jsonResponse.workTimes.map((tl: any) => ({
                id: tl.id,
                workShiftId: tl.workShiftId,
                timeLogTypeId: tl.timeLogTypeId,
                startTime: tl.startTime,
                endTime: tl.endTime,
                userDenyStartLocation: tl.endTime,
                startLatitude: tl.startLatitude,
                startLongitude: tl.startLongitude,
                userDenyEndLocation: tl.userDenyEndLocation,
                endLatitude: tl.endLatitude,
                endLongitude: tl.endLongitude,
                workShift: tl.workShift,
                timeLogType: tl.timeLogType,
                WorkShiftAttachments: tl.WorkShiftAttachments
            }))
        };
        return workShift;
    } catch (error) {
        console.error('Error en la solicitud:', error);
        return null;
    }
}

export async function UpdateCurrentWorkTimeInWorkShift(
    id: number,
    closingWorkTimeId: number,
    openingWorkTimeTypeId: number,
    employeeId?: number
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/UpdateCurrentWorkTimeInWorkShift", {
            id: id,
            employeeId: employeeId ?? employee.id,
            closingWorkTimeId: closingWorkTimeId,
            openingWorkTimeTypeId: openingWorkTimeTypeId,
        });

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function CloseCurrentWorkTimeInWorkShift(
    id: number,
    closingWorkTimeId: number,
    employeeId?: number
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const location = await GetLocation();
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/CloseCurrentWorkTimeInWorkShift", {
            id: id,
            employeeId: employeeId ?? employee.id,
            closingWorkTimeId: closingWorkTimeId,
            location: location
        });

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function OpenNewWorkTimeInWorkShift(
    id: number,
    openingWorkTimeTimeLogTypeId: number,
    employeeId?: number
): Promise<boolean> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const location = await GetLocation();
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/OpenNewWorkTimeInWorkShift", {
            id: id,
            employeeId: employeeId ?? employee.id,
            openingWorkTimeTimeLogTypeId: openingWorkTimeTimeLogTypeId,
            location: location
        });

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return false;
}

export async function AddWorkShiftAttachment(
    id: number,
    file: File
): Promise<WorkShiftAttachment | null> {
    try {
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        const formData = new FormData();
        formData.append("file", file);

        const response = await axiosRRHHEmployeeAPI.post(
            `/api/timecontrol/AddWorkShiftAttachment/${id}/${employee.id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            return response.data;;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export async function DeleteWorkShiftAttachment(
    workShiftId: number,
    workShiftAttachmentId: number
): Promise<boolean> {
    const response = await axiosRRHHEmployeeAPI.delete(`/api/timecontrol/DeleteWorkShiftAttachment/${workShiftId}/${workShiftAttachmentId}`);
    return response.status === 200 ? response.data : false;
}

export async function GetTimeLogTypes(
    currentTimeLogTypeId?: number
): Promise<TimeLogType[] | null> {
    try {
        const response = await axiosRRHHEmployeeAPI.get("/api/timecontrol/GetTimeLogTypes");

        const jsonResponse = response.data;
        if (response.status === 200) {
            let timeLogTypes: TimeLogType[] = jsonResponse.map((tl: any) => ({
                timeLogTypeId: tl.id,
                category: tl.category,
                name: tl.name,
                description: tl.description
            }));

            if (currentTimeLogTypeId !== undefined) {
                timeLogTypes = timeLogTypes.filter(tl => tl.id !== currentTimeLogTypeId);
            }

            return timeLogTypes;
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return null;
}

export const CalculateTotalWorkTimeInSeconds = async (workTimes: IWorkTime[]): Promise<number> => {
    let totalWorkTimeInSeconds = 0;

    for (const workTime of workTimes) {
        if (workTime.timeLogType.category === TimeLogTypeCategory.WorkTime) {
            const startTime = new Date(workTime.startTime);
            const endTime = workTime.endTime ? new Date(workTime.endTime) : await GetCurrentDateTime();
            const diffInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
            totalWorkTimeInSeconds += diffInSeconds;
        }
    }

    return totalWorkTimeInSeconds;
};

export async function GetYears(): Promise<number[] | []> {
    const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetYears/${employee.id}`);
    return response.status === 200 ? response.data : [];
}

export async function GetMonthsByYear(
    year: number
): Promise<number[] | []> {
    const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetMonthsByYear/${year}/${employee.id}`);
    return response.status === 200 ? response.data : [];
}

export async function GetWorkShiftsByEmployeeByMonthByYear(
    month: number,
    year: number
): Promise<WorkShift[]> {
    const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
    const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetWorkShiftsByEmployeeByMonthByYear/${month}/${year}/${employee.id}`);
    return response.status === 200 ? response.data : [];
}

export async function UpdateWorkShiftEmployeeRemarks(
    id: number,
    employeeRemarks: string
): Promise<boolean> {
    const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
    const response = await axiosRRHHEmployeeAPI.post(`/api/timecontrol/UpdateWorkShiftRemarks/${id}/${employee.id}`,
        {
            remarks: employeeRemarks
        }
    );
    return response.status === 200;
}

export async function GetCurrentDateTime(): Promise<Date> {
    try {
        const response = await axiosRRHHEmployeeAPI.get(`/api/timecontrol/GetCurrentDateTime`);

        if (response.status === 200) {
            return new Date(response.data);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    return new Date();
}

export async function GetWorkShiftsExportExcel(
    fileName: string,
    workShiftFilter?: Record<string, any>
): Promise<void> {
    try {
        const response = await axiosRRHHEmployeeAPI.post("/api/timecontrol/GetWorkShiftsExportExcel", workShiftFilter ?? {}, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);

            link.click();
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    }
}

export async function GenerateMonthSummaryForEmployee(
    month: number,
    year: number
): Promise<void> {
    const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
    if ((await axiosRRHHEmployeeAPI.get(`/api/TimeControl/CheckMonthSummaryForEmployee/${employee.id}/${month}/${year}`)).status === 200) {
        const response = await axiosRRHHEmployeeAPI.get(`/api/TimeControl/GenerateMonthSummaryForEmployee/${employee.id}/${month}/${year}`, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Extraer el nombre del archivo del header 'Content-Disposition'
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'download.pdf'; // Nombre por defecto si no se encuentra uno en el header
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch && filenameMatch[1]) {
                    fileName = filenameMatch[1].replace(/['"]/g, '');
                }
            }

            // Crear un elemento 'a' y forzar el download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);

            link.click();

            // Limpiar y remover el link
            window.URL.revokeObjectURL(url);
            link.parentNode?.removeChild(link);
        }
    }
}