import { useState, useEffect, useContext, useRef } from "react";
import { NotificationContext } from '../../contexts/NotificationContext';
import { LoginUser } from "../../services/authentication/AuthenticationService";
import { Authentication } from "../../interfaces/authentication/Authentication";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthProps } from "../../App";
import Alert from "../../components/common/alert/Alert";
import { getImagePath, getQueryParam } from "../../resources/utils/common";
import { useSpinner } from "../../contexts/SpinnerContext";
import EnvironmentAlert from "../../components/common/alert/EnvironmentAlert";

const applicationName = process.env.REACT_APP_NAME;

export default function Login({ setIsLoggedIn }: AuthProps) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  const { setIsLoading } = useSpinner();
  const { showNotification } = useContext(NotificationContext);
  const [authentication, setAuthentication] = useState<Authentication | undefined>(undefined);
  const [sessionExpired, setSessionExpired] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);

  let spinnerTimeout;
  const loginAttempt = async (username: string, password: string) => {
    spinnerTimeout = setTimeout(() => {
      if (!dataLoaded) setIsLoading(true);
    }, 500);

    let dataLoaded = false;

    try {
      const authentication = await LoginUser(username, password, applicationName);
      dataLoaded = true;

      clearTimeout(spinnerTimeout);

      if (authentication === null) {
        showNotification({ color: "orange", messageTitle: t("login.errorTitle"), messageText: t("login.errorText") });
        if (passwordRef.current) passwordRef.current.value = '';
      } else {
        setAuthentication(authentication);
        //changeLanguage(user.user.language);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    if (username === "" || password === "") {
      showNotification({ color: "orange", messageTitle: t("login.errorTitle"), messageText: t("login.errorFieldsRequired") });
    } else {
      loginAttempt(username, password);
    }
  };

  /* ****************************************************************************************** */
  useEffect(() => {
    changeLanguage("es"); // Mientras no se haga la aplicación bilingüe, esto se mantiene aquí
    const expiredToken = getQueryParam('expired_token', window.location.search);
    if (expiredToken === '1') {
      setSessionExpired(true);
    }
  }, []);
  /* ****************************************************************************************** */

  useEffect(() => {
    if (authentication !== undefined) {
      showNotification({ color: "green", messageTitle: t("login.successTitle"), messageText: t("login.successText") });
      setIsLoggedIn(true);
    }
  }, [authentication]);

  return (
    <>
      <EnvironmentAlert message={process.env.REACT_APP_NOT_PRODUCTIVE_ENVIRONMENT_PRODUCTION_URL} />
      <div className="flex h-full-vh">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src={getImagePath("images/logo-ocon-black.webp")} className="" alt="logo" />
              <h2 className="mt-6 text-2xl font-bold italic tracking-tight text-gray-900 text-center">
                {t("login.employeePortalTitle")}
              </h2>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  {sessionExpired && <Alert text={t("login.tokenExpired")} type="warning" />}
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t("login.username")}
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        inputMode="text"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t("login.password")}
                    </label>
                    <div className="mt-2">
                      <input
                        ref={passwordRef}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        inputMode="text"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    {/* <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        {t("login.rememberMe")}
                      </label>
                    </div> */}

                    <div className="text-sm">
                      <Link to="/forgotten" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {t("login.forgotPassword")}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {t("login.signIn")}
                    </button>
                  </div>
                  {/* <button onClick={() => changeLanguage("en")}>EN</button>
                  <button onClick={() => changeLanguage("es")}>ES</button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={getImagePath("images/login-background.webp")}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
