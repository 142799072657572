import React, { createContext, ReactNode, useState } from 'react';

interface NotificationState {
    visible: boolean;
    color: string;
    messageTitle: string;
    messageText: string;
}

interface NotificationContextProps {
    state: NotificationState;
    setNotificationState: React.Dispatch<React.SetStateAction<NotificationState>>;
    resetNotificationState: () => void;
    showNotification: (props: { color: string, messageTitle: string, messageText: string }) => void;
}

const defaultState: NotificationState = {
    visible: false,
    color: "green",
    messageTitle: "Default title",
    messageText: "Default text",
};

export const NotificationContext = createContext<NotificationContextProps>({} as NotificationContextProps);

interface NotificationProviderProps {
    children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [notificationState, setNotificationState] = useState<NotificationState>(defaultState);

    const resetNotificationState = () => {
        setNotificationState({ ...defaultState, visible: false });
    };

    const showNotification = ({ color, messageTitle, messageText }: { color: string; messageTitle: string; messageText: string }) => {
        resetNotificationState();
        setTimeout(() => {
            setNotificationState({
                visible: true,
                color,
                messageTitle,
                messageText,
            });
        }, 0);
    };

    return (
        <NotificationContext.Provider
            value={{
                state: notificationState,
                setNotificationState,
                resetNotificationState,
                showNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};