export function SetCookie(name: string, value: string, expiresInHours: number) {
  const date = new Date();
  date.setTime(date.getTime() + expiresInHours * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;Secure;SameSite=Strict`;
}

export function GetCookie(name: string): string | undefined {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const [cookieName, cookieValue] = cookie.split("=");

    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return undefined;
}

export function DeleteCookie(name: string) {
  const date = new Date();
  date.setTime(date.getTime() - 1);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=;${expires};path=/;Secure;SameSite=Strict`;
}

export function AddAuthenticationCookies(token: string, user: any, employee: any) {
  SetCookie("token", token, 1000);
  SetCookie("user", user, 1000);
  SetCookie("employee", employee, 1000);
}

export function RemoveLoginCookies() {
  DeleteCookie("token");
  DeleteCookie("user");
  DeleteCookie("employee");
}