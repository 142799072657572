import { useNavigate, BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { RemoveLoginCookies } from "../../services/cookie/CookieService";
import { useTranslation } from "react-i18next";
import { GetCookie } from "../../services/cookie/CookieService";
import { User } from "../../interfaces/user/User";
import { RouteItem } from "../../interfaces/common/RouteItem";
import Home from '../section/Home';
import TimeControl from '../section/TimeControl';
import Documents from '../section/Documents';
import { Employee } from "../../interfaces/employee/Employee";
import { AuthProps } from "../../App";
import EmployeeProfile from "../employee/EmployeeProfile";
import NotFound from "../not-found/NotFound";
import { useSpinner } from "../../contexts/SpinnerContext";
import EnvironmentAlert from "../../components/common/alert/EnvironmentAlert";
import { GetInstallationManual } from "../../services/documentation/DocumentationService";
import { Tooltip } from 'react-tooltip';
import { PDFViewer } from "../../components/common/viewer/PDFViewer";
import { getImagePath } from "../../resources/utils/common";

interface Permissions {
  [key: string]: Permissions | string[] | {};
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function getMainMenu(navigationComplete: RouteItem[], user: User | undefined, t: Function, selectedKey: string): RouteItem[] {
  if (!user) {
    return [];
  }

  return navigationComplete
    .filter(item => (user.permissions as { [key: string]: any }).hasOwnProperty(item.key))
    .map((item) => {
      const newItem = { ...item, name: t("dashboard." + item.key), current: selectedKey === item.key };

      if (newItem.children) {
        newItem.children = newItem.children
          .filter(child => (user.permissions[item.key] as { [key: string]: any }).hasOwnProperty(child.key))
          .map(child => ({ ...child, name: t("dashboard." + child.key), current: selectedKey === child.key }));
      }

      return newItem;
    });
}

function getUserFromCookie() {
  return JSON.parse(GetCookie("user") || "{}");
}

function getEmployeeFromCookie() {
  return JSON.parse(GetCookie("employee") || "{}");
}

export default function Dashboard({ setIsLoggedIn }: AuthProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoading } = useSpinner();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [navigation, setNavigation] = useState<RouteItem[]>([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const [showInstallationManualPDF, setShowInstallationManualPDF] = useState<boolean>(false);

  const navigationComplete: RouteItem[] = [
    {
      key: "profile",
      route: "/profile",
      component: EmployeeProfile,
      icon: UserIcon,
      current: selectedKey === "profile"
    },
    {
      key: "timeControl",
      route: "/time-control",
      component: TimeControl,
      icon: CalendarIcon,
      current: selectedKey === "timeControl"
    },
    {
      key: 'home',
      route: "/home",
      component: Home,
      icon: HomeIcon,
      current: selectedKey === "home"
    },
    {
      key: "documents",
      route: "/documents",
      component: Documents,
      icon: DocumentDuplicateIcon,
      current: selectedKey === "documents"
    },
  ]

  const renderRoutes = (navigationItems: RouteItem[]): JSX.Element[] => {
    return navigationItems.map((item, index) => {
      const routes: JSX.Element[] = [];

      if (item.component) {
        // Añadir los parámetros de ruta si existen
        const path = item.routeParams ? `${item.route}${item.routeParams}` : item.route;
        routes.push(<Route key={`route-${index}`} path={path} element={<item.component />} />);
      }

      if (item.children) {
        routes.push(...renderRoutes(item.children));
      }

      return routes;
    }).flat();
  };

  const userNavigation = [
    {
      key: "profile",
      onClick: () => {
        navigate("/profile");
      }
    },
    {
      key: "logout",
      onClick: () => {
        RemoveLoginCookies();
        setIsLoggedIn(false);
        navigate("/");
      }
    },
  ].map(item => ({
    ...item,
    name: t("dashboard." + item.key),
  }));

  useEffect(() => {
    setUser(getUserFromCookie());
    setEmployee(getEmployeeFromCookie());
  }, []);

  useEffect(() => {
    if (location?.pathname !== "/") {
      let item = navigationComplete.find(item => item.route === location.pathname);
      if (item !== undefined) {
        setSelectedKey(item.key);
        return;
      }
    }
    if (user && user.permissions) {
      const firstPermission = Object.keys(user.permissions)[0];
      setSelectedKey(firstPermission);
    }
  }, [user]);

  useEffect(() => {
    setNavigation(getMainMenu(navigationComplete, user, t, selectedKey));
  }, [selectedKey]);

  function findItemWithPath(items: RouteItem[], path: string, parents: string[] = []): [RouteItem | null, string[]] {
    for (let item of items) {
      const currentPath = [...parents, item.key];
      if (item.route && path.startsWith(item.route)) {
        return [item, currentPath];
      } else if (item.children) {
        let [found, foundPath] = findItemWithPath(item.children, path, currentPath);
        if (found) {
          return [found, foundPath];
        }
      }
    }
    return [null, []];
  }

  function hasPermission(permissions: Permissions, key: string): boolean {
    if (permissions.hasOwnProperty(key)) {
      return true;
    }
    for (const prop in permissions) {
      if (typeof permissions[prop] === 'object' && hasPermission(permissions[prop] as Permissions, key)) {
        return true;
      }
    }
    return false;
  }

  function findFirstAvailableRoute(navigationItems: RouteItem[], user: User | undefined): string {
    if (!user || !user.permissions) {
      return '/'; // O alguna ruta predeterminada
    }

    for (const item of navigationItems) {
      if (hasPermission(user.permissions, item.key)) {
        if (item.route) {
          return item.route;
        }
      }
      if (item.children) {
        for (const child of item.children) {
          if (hasPermission(user.permissions, child.key)) {
            return child.route ?? '/'; // Proporciona una ruta predeterminada si child.route es undefined
          }
        }
      }
    }
    return '/'; // O alguna ruta predeterminada
  }

  useEffect(() => {
    if (location.pathname !== "/") {
      const [selectedNavItem, path] = findItemWithPath(navigationComplete, location.pathname);
      if (selectedNavItem && selectedNavItem.key !== selectedKey) {
        setSelectedKey(selectedNavItem.key);
        const newPath = path.slice(0, -1);
        if (!arraysEqual(newPath, expandedKeys)) {
          setExpandedKeys(newPath); // Excluir la clave del propio elemento seleccionado
        }
      }
    } else {
      // Navega a la primera ruta disponible
      const firstAvailableRoute = findFirstAvailableRoute(navigationComplete, user);
      navigate(firstAvailableRoute);
    }
  }, [location, navigationComplete, selectedKey, expandedKeys, user]);

  // Función auxiliar para comparar arreglos
  function arraysEqual(a: string[], b: string[]): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const handleSidebarClick = (key: string) => {
    setSelectedKey(key);
    setSidebarOpen(false);
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">{t("dashboard.closeSidebar")}</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="mt-3 h-14 w-auto" src={getImagePath("images/logo-ocon-white.webp")} alt="Transportes Ocon - Portal del empleado" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.route ?? '/'}
                                onClick={() => handleSidebarClick(item.key)}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          {t("dashboard.myDelegations")}
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          <li key={employee?.workCenter?.name ?? t("dashboard.inactiveEmployee")}>
                            <a
                              href="#"
                              className="bg-gray-800 text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            >
                              <span className="truncate">{employee?.workCenter?.name ?? t("dashboard.inactiveEmployee")}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="mt-auto">
                        <a
                          href="#"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                        >
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {t("dashboard.settings")}
                        </a>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img className="mt-7 h-16 w-auto" src={getImagePath("images/logo-ocon-white.webp")} alt="Transportes Ocon - Portal del empleado" />
          </div>
          <nav className="mt-5 flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.key}>
                      <Link
                        to={item.route ?? '/'}
                        onClick={() => handleSidebarClick(item.key)}
                        className={classNames(
                          item.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  {t("dashboard.myDelegations")}
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  <li key={employee?.workCenter?.name ?? t("dashboard.inactiveEmployee")}>
                    <a
                      href="#"
                      className="bg-gray-800 text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <span className="truncate">{employee?.workCenter?.name ?? t("dashboard.inactiveEmployee")}</span>
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li className="mt-auto">
                <a
                  href="#"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                >
                  <Cog6ToothIcon
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  {t("dashboard.settings")}
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <EnvironmentAlert message={process.env.REACT_APP_NOT_PRODUCTIVE_ENVIRONMENT_MESSAGE} />
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">{t("dashboard.openSidebar")}</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            {/* 
            Cuadro de búsqueda por si más adelante se quiere utilizar
            <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                {t("dashboard.search")}
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder={`${t("dashboard.searchPlaceholder")}`}
                type="search"
                name="search"
              />
            </form> */}
            <div className="relative flex flex-1">&nbsp;</div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <button
                type="button"
                className="block lg:hidden -m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                // onClick={async () => {
                //   setIsLoading(true);
                //   await GetInstallationManual();
                //   setIsLoading(false);
                // }}
                onClick={() => setShowInstallationManualPDF(true)}
              >
                <span className="sr-only">{t("dashboard.viewInstallationManual")}</span>
                <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <PDFViewer
                showPDF={showInstallationManualPDF}
                setShowPDF={setShowInstallationManualPDF}
                pdfUrl="documentation/installation-manual.pdf"
              />
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{t("dashboard.viewNotifications")}</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">{t("dashboard.openUserMenu")}</span>
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src={getImagePath("images/default-user-icon.webp")}
                    alt=""
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      aria-hidden="true"
                    >
                      {employee?.firstName} {employee?.lastName1}
                    </span>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={item.onClick}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "w-full text-left block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <Routes>
              {renderRoutes(navigationComplete)}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </main>
      </div>
    </div >
  );
}
