import { WorkTime as IWorkTime } from "../../interfaces/time-control/WorkTime";
import { LockOpenIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

interface WorkTimeProps {
    workTime: IWorkTime;
    onUpdateParent: () => void;
}

export default function WorkTime(props: WorkTimeProps) {
    const { t, i18n } = useTranslation();
    const { workTime } = props;

    return (
        <div className="mt-5">
            <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between">
                <div className="flex items-center sm:ml-4 sm:mt-0">
                    {workTime.endTime === null ? (
                        <LockOpenIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    ) : (
                        <LockClosedIcon className="h-6 w-6 text-blue-800" aria-hidden="true" />
                    )}
                    <div className="ml-6">
                        <div className="text-sm text-gray-600 sm:flex sm:items-center">
                            <div>{new Date(workTime.startTime).toLocaleString()}</div>
                            <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                                &middot;
                            </span>
                            <div>{workTime.endTime ? new Date(workTime.endTime).toLocaleString() : <div className="text-green-600 font-semibold">{t("workTime.currentWorkTime")}</div>}</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}