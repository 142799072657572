import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Employee } from "../../interfaces/employee/Employee";
import { GetCookie } from "../../services/cookie/CookieService";
import { useNavigate } from "react-router-dom";
import { getImagePath } from "../../resources/utils/common";

const user = {
  name: 'Rebecca Nicholas',
  role: 'Product Designer',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const stats = [


  { label: 'Vacation days left', value: 12 },
  { label: 'Sick days left', value: 4 },
  { label: 'Personal days left', value: 2 },
]

export default function Home() {
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setEmployee(JSON.parse(GetCookie("employee") || "{}"));
  }, []);

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <h2 className="sr-only" id="profile-overview-title">
        {t("home.viewProfile")}
      </h2>
      <div className="bg-white p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="flex-shrink-0">
              <img className="mx-auto h-20 w-20 rounded-full" src={getImagePath("images/default-user-icon.webp")} alt="" />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-600">{t("home.welcome")},</p>
              <p className="text-xl font-bold text-gray-900 sm:text-2xl">{employee?.firstName} {employee?.lastName1}</p>
              <p className="text-sm font-medium text-gray-600">{employee?.email}</p>
            </div>
          </div>
          <div className="mt-5 flex justify-center sm:mt-0">
            <button
              type="button"
              onClick={() => {
                navigate("/profile");
              }}
              className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {t("home.viewProfile")}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        {stats.map((stat) => (
          <div key={stat.label} className="px-6 py-5 text-center text-sm font-medium">
            <span className="text-gray-900">{stat.value}</span> <span className="text-gray-600">{stat.label}</span>
          </div>
        ))}
      </div> */}
    </div>
  )
}
