import { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../contexts/NotificationContext';
import { UseEmployeeInfo } from '../../hooks/employee/UseEmployee';
import { useNavigate } from 'react-router-dom';
import SimpleHeaderWithTwoButtons from '../../components/common/header/SimpleHeaderWithTwoButtons';
import FormReadOnly from '../../components/common/form/FormReadOnly';
import { FormFieldReadOnly } from '../../components/common/form/FormFieldReadOnly';
import { FormField } from '../../components/common/form/FormField';
import Form from '../../components/common/form/Form';
import { User } from '../../interfaces/user/User';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { UseUser } from '../../hooks/user/UseUser';
import { GetCookie } from '../../services/cookie/CookieService';
import { PutUser } from '../../services/user/UserService';
import { useSpinner } from '../../contexts/SpinnerContext';
import { NotificationTypes } from '../../components/common/Notification';

export default function EmployeeProfile() {
  const { t } = useTranslation();
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useSpinner();
  const navigate = useNavigate();
  const employee = UseEmployeeInfo(JSON.parse(GetCookie("employee") || "{}").id);
  const user = UseUser(JSON.parse(GetCookie("user") || "{}").id);
  const userFormRef = useRef<FormikProps<User>>();

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [user]);

  const handleValidateAndSubmit = async () => {
    if (userFormRef.current) {
      const userValues = await userFormRef.current.submitForm();
      const userErrors = await userFormRef.current.validateForm();

      if (Object.keys(userErrors).length === 0) {
        updateUser(userValues!);
      } else {
        showNotification({ color: NotificationTypes.Danger, messageTitle: t("common.errorDataValidation"), messageText: Object.values(userErrors).map(error => `- ${error}`).join('\n') });
      }
    }
  };

  const updateUser = async (user: User) => {
    setIsLoading(true);
    const result = await PutUser(user);
    if (result) {
      setIsLoading(false);
      if (typeof result === 'string') {
        setIsLoading(false);
      } else {
        showNotification({ color: NotificationTypes.Success, messageTitle: t("common.updatedOkTitle"), messageText: t("common.updatedOkSubtitle") });
      }
    } else {
      setIsLoading(false);
      showNotification({ color: NotificationTypes.Warning, messageTitle: t("common.updatedErrorTitle"), messageText: t("common.updatedErrorSubtitle") });
    }
  };

  const userValidationSchema = Yup.object({
    password: Yup.string()
      .required('La contraseña es obligatoria')
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .max(30, 'La contraseña no debe tener más de 30 caracteres')
      .matches(/(?=.*[0-9])/, 'La contraseña debe contener al menos un número')
      .matches(/(?=.*[a-zA-Z])/, 'La contraseña debe contener al menos una letra'),
  });

  return (
    <div className="px-2 sm:px-4">
      <SimpleHeaderWithTwoButtons
        title={t("profile.title")}
        textSize="text-3xl"
        buttonText1={t("common.goBack")}
        buttonText2={t("common.save")}
        button1Click={() => navigate("/home")}
        button2Click={handleValidateAndSubmit}
      />
      <div className="mt-4">
        <FormReadOnly>
          <div className="space-y-10">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">{t("profile.personalData")}</h2>
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <FormFieldReadOnly colspan={1} label={t("employee.documentType")} value={employee?.documentType} />
                <FormFieldReadOnly colspan={1} label={t("employee.documentNumber")} value={employee?.documentNumber} />
              </div>
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <FormFieldReadOnly colspan={1} label={t("employee.firstName")} value={employee?.firstName} />
                <FormFieldReadOnly colspan={1} label={t("employee.lastName1")} value={employee?.lastName1} />
                <FormFieldReadOnly colspan={1} label={t("employee.lastName2")} value={employee?.lastName2} />
                <FormFieldReadOnly colspan={2} label={t("employee.email")} value={employee?.email} />
              </div>
            </div>
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">{t("employee.currentStatus")}</h2>
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <FormFieldReadOnly colspan={2} label={t("employee.company")} value={employee?.company} />
                <FormFieldReadOnly colspan={2} label={t("employee.workCenter")} value={employee?.workCenter} />
                <FormFieldReadOnly colspan={1} label={t("employee.job")} value={employee?.job} />
              </div>
            </div>
            <Form initialValues={user!} validationSchema={userValidationSchema} ref={userFormRef}>
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t("user.dataSectionTitle")}</h2>
                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <FormFieldReadOnly colspan={1} label={t("user.username")} value={user?.username} />
                  <FormField colspan={1} label={t("user.password")} name="password" />
                </div>
              </div>
            </Form>
          </div>
        </FormReadOnly>
      </div>
    </div>
  )
}
