import { useTranslation } from "react-i18next";

interface PDFViewerProps {
    showPDF: boolean;
    setShowPDF: (show: boolean) => void;
    pdfUrl: string;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ showPDF, setShowPDF, pdfUrl }) => {
    const { t } = useTranslation();
    if (!showPDF) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-75">
            <div className="flex justify-end items-center h-15 bg-gray-800 p-2">
                <button
                    onClick={() => setShowPDF(false)}
                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    {t("common.close")}
                </button>
            </div>
            <div className="relative h-[calc(100%-60px)]">
                <iframe
                    src={pdfUrl}
                    className="absolute inset-0 w-full h-full border-none"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};