import { WorkTime } from "./WorkTime";
import { TimeOff } from "./TimeOff";

export enum TimeLogTypeCategory {
    WorkTime = 0,
    TimeOff = 1,
}

export interface TimeLogType {
    id: number;
    category: TimeLogTypeCategory;
    name: string;
    description: string;
    workTimes?: WorkTime[];
    timeOffs?: TimeOff[];
}