// Table.tsx
import React from 'react';
import { TableProps } from '../../../interfaces/common/list/TableProps';
import { EyeIcon } from '@heroicons/react/24/solid';
import { Tooltip } from 'antd';

const Table = <T extends {}>({
    data,
    columns,
    onRowButtonClick,
    rowButtonText,
    marginTop
}: TableProps<T>): React.ReactElement => {
    return (
        <div className={`-mx-0 ${marginTop !== undefined ? `mt-${marginTop}` : "mt-8"}`}>
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th
                                key={index}
                                className={`${column.className} ${column.responsiveClassName || ''
                                    } ${column.hideInMobile ? 'hidden sm:table-cell' : ''}`}
                            >
                                {column.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, colIndex) => {
                                const cellClassName = `${column.cellClassName || ''} ${column.hideInMobile ? 'hidden sm:table-cell' : ''}`;

                                return (
                                    <td
                                        key={colIndex}
                                        className={`${cellClassName} ${column.responsiveClassName || ''}`}
                                    >
                                        {column.format ? column.format(row) : `${row[column.dataKey]}`}
                                        {column.collapsedContent && (
                                            <dl className="font-normal lg:hidden">
                                                {column.collapsedContent(row)}
                                            </dl>
                                        )}
                                    </td>
                                );
                            })}
                            {onRowButtonClick && (
                                <td className="h-[70px] md:h-0">
                                    <button className="rounded-md bg-indigo-50 px-2.5 py-2.5 md:py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100" onClick={() => onRowButtonClick(row)}>{rowButtonText}</button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
