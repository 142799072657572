import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { GetCurrentWorkShiftByEmployee, CalculateTotalWorkTimeInSeconds, CloseCurrentWorkTimeInWorkShift, OpenNewWorkTimeInWorkShift } from "../../services/time-control/TimeControlService";
import { BarsArrowUpIcon, BarsArrowDownIcon, BuildingOfficeIcon, UserIcon } from '@heroicons/react/20/solid'
import { WorkShift } from "../../interfaces/time-control/WorkShift";
import SectionHeader from "../../components/common/header/SectionHeader";
import WorkTime from '../../components/time-control/WorkTime';
import WorkShiftStart from '../../components/time-control/WorkShiftStart';
import Alert from '../../components/common/alert/Alert';
import Tabs, { TabPermissionType } from '../../components/common/navigation/Tabs';
import WorkShiftHistory from '../../components/time-control/WorkShiftHistory';
import Dropdown, { IDropdownBlock } from '../../components/common/dropdown/Dropdown';
import Modal from '../../components/common/overlay/Modal';
import { Modal as AntdModal } from 'antd';
import WorkShiftEnd from '../../components/time-control/WorkShiftEnd';
import { PauseIcon, PlayIcon, StopIcon } from '@heroicons/react/24/solid';
import { useSpinner } from '../../contexts/SpinnerContext';
import { ClockIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { getFirstTab } from '../../resources/utils/common';

export default function TimeControl() {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useSpinner();
  const [workShift, setWorkShift] = useState<WorkShift | null>(null);
  const [totalWorkedTimeInSeconds, setTotalWorkedTimeInSeconds] = useState<number>(0);
  const [totalWorkedTimeInTimeFormat, setTotalWorkedTimeInTimeFormat] = useState<string>("");
  const [workTimeStartTimeAscendingSort, setWorkTimeStartTimeAscendingSort] = useState<boolean>(false);
  const [modalFinishWorkShiftIsOpen, setModalFinishWorkShiftIsOpen] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);

  const tabs = [
    { key: "currentWorkshift", name: t("timeControl.currentWorkshift"), icon: ClockIcon, current: true, permission: [TabPermissionType.OnlyActiveUsers] },
    { key: "workshiftHistory", name: t("timeControl.workshiftHistory"), icon: DocumentMagnifyingGlassIcon, current: false },
  ];
  const sectionKey = "timeControl";
  const [activeTab, setActiveTab] = useState<string>(getFirstTab(tabs, sectionKey) || "currentWorkshift");

  const dropdownWorkTimesOptions: IDropdownBlock[] = [
    {
      items: [
        { label: t("timeControl.workTimeStartTimeAscendingSort"), icon: BarsArrowUpIcon, action: () => setWorkTimeStartTimeAscendingSort(true) },
        { label: t("timeControl.workTimeStartTimeDescendingSort"), icon: BarsArrowDownIcon, action: () => setWorkTimeStartTimeAscendingSort(false) },
      ]
    },
  ];

  const updateParent = () => {
    setIsLoading(true);
    fetchWorkShift();
  };

  const closeWorkShiftEndModal = () => {
    setModalFinishWorkShiftIsOpen(false)
  };

  const isLoadingChildren = (state: boolean) => {
    setIsLoading(state)
  };

  const fetchWorkShift = async () => {
    const result = await GetCurrentWorkShiftByEmployee();
    if (result !== undefined) {
      setWorkShift(result);
    }
    setIsLoading(false);
  };

  const changeTab = (tabName: string) => {
    setActiveTab(tabName);
    if (tabName === "currentWorkshift") {
      // TODO: esto habria que refactorizarlo con tiempo...
      fetchWorkShift();
    }
  };

  useEffect(() => {
    fetchWorkShift();
  }, []);

  useEffect(() => {
    const calculateWorkTime = async () => {
      if (workShift && workShift.workTimes) {
        const timeInSeconds = await CalculateTotalWorkTimeInSeconds(workShift.workTimes);
        setTotalWorkedTimeInSeconds(timeInSeconds);
      }
      else {
        setTotalWorkedTimeInSeconds(0);
      }
    }
    calculateWorkTime();

    setIsWorking(!!workShift?.workTimes?.find(workTime => workTime.endTime === null));

    const timer = setInterval(() => {
      if (workShift && workShift.workTimes?.find(workTime => workTime.endTime === null)) {
        setTotalWorkedTimeInSeconds(totalWorkedTimeInSeconds => totalWorkedTimeInSeconds + 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [workShift]);

  useEffect(() => {
    const hours = Math.floor(totalWorkedTimeInSeconds / 3600);
    const minutes = Math.floor((totalWorkedTimeInSeconds % 3600) / 60);
    const seconds = Math.floor(totalWorkedTimeInSeconds % 60);

    setTotalWorkedTimeInTimeFormat(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
  }, [totalWorkedTimeInSeconds]);

  const handlePauseWorkShift = async () => {
    if (workShift) {
      setIsLoading(true);
      await CloseCurrentWorkTimeInWorkShift(workShift.id, workShift.workTimes?.find(workTime => workTime.endTime === null)?.id ?? 1).then((result) => {
        if (result) {
          AntdModal.success({
            title: t("workShift.pauseSuccessfulTitle"),
            content: t("workShift.pauseSuccessfulSubtitle"),
            centered: true,
            width: 600,
          })
          updateParent();
        }
      });
      setIsLoading(false);
    }
  };

  const handleResumeWorkShift = async () => {
    if (workShift) {
      setIsLoading(true);
      await OpenNewWorkTimeInWorkShift(workShift.id, 1).then((result) => {
        if (result) {
          AntdModal.success({
            title: t("workShift.resumeSuccessfulTitle"),
            content: t("workShift.resumeSuccessfulSubtitle"),
            centered: true,
            width: 600,
          })
          updateParent();
        }
      });
      setIsLoading(false);
    }
  };

  const handleFinishWorkShift = async () => {
    setModalFinishWorkShiftIsOpen(true);
  };

  return (
    <div className="px-2 sm:px-4">
      <SectionHeader title={t("timeControl.title")} textSize="text-3xl" />
      <div className="mt-4">
        <Tabs setTab={changeTab} activeTab={activeTab} tabs={tabs} />
        {activeTab === "currentWorkshift" && (
          workShift && workShift.isActive ? (
            <>
              {isWorking ? (
                <Alert text={t("timeControl.workShiftInProgressAndWorking")} type="success" />
              ) : (
                <Alert text={t("timeControl.workShiftInProgressAndNotWorking")} type="warning" />
              )}
              <h3 className="mt-6 mb-2 text-base font-medium leading-6 text-gray-900">
                {t("timeControl.workShiftStartTime")}: {new Date(workShift.startShift).toLocaleString()}
              </h3>
              <h3 className="mb-6 text-base font-medium leading-6 text-gray-900">
                {t("timeControl.totalComputedWorkingTime")}: {totalWorkedTimeInTimeFormat}
              </h3>
              <div>
                {isWorking ? (
                  <button
                    type="button"
                    className="mb-3 mr-3 sm:mr-5 w-full sm:w-auto inline-flex items-center rounded-md bg-indigo-50 px-5 py-4 sm:text-sm text-lg font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    onClick={handlePauseWorkShift}
                  >
                    <PauseIcon className="-ml-0.5 mr-2.5 sm:h-7 h-11 sm:w-7 w-11" aria-hidden="true" />
                    {t("timeControl.pauseWorkShift")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="mb-3 mr-3 sm:mr-5 w-full sm:w-auto inline-flex items-center rounded-md bg-green-50 px-5 py-4 sm:text-sm text-lg font-semibold text-green-600 shadow-sm hover:bg-green-100"
                    onClick={handleResumeWorkShift}
                  >
                    <PlayIcon className="-ml-0.5 mr-2.5 sm:h-7 h-11 sm:w-7 w-11" aria-hidden="true" />
                    {t("timeControl.resumeWorkShift")}
                  </button>
                )}
                <button
                  type="button"
                  className="mb-3 mr-3 sm:mr-5 w-full sm:w-auto inline-flex items-center rounded-md bg-orange-50 px-5 py-4 sm:text-sm text-lg font-semibold text-orange-600 shadow-sm hover:bg-orange-100"
                  onClick={handleFinishWorkShift}
                >
                  <StopIcon className="-ml-0.5 mr-2.5 sm:h-7 h-11 sm:w-7 w-11" aria-hidden="true" />
                  {t("timeControl.finishWorkShift")}
                </button>
                <Modal isOpen={modalFinishWorkShiftIsOpen} onClose={() => setModalFinishWorkShiftIsOpen(false)}>
                  <WorkShiftEnd workShift={workShift} onUpdateParent={updateParent} closeModal={closeWorkShiftEndModal} isLoading={isLoadingChildren} />
                </Modal>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-4 mb-4">
                <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4 md:mb-0">
                  {t("timeControl.workTimesTitle")}
                </h3>
                <div className="flex justify-end w-full md:w-auto">
                  <Dropdown title={t("common.sort")} blocks={dropdownWorkTimesOptions} />
                </div>
              </div>
              {workShift.workTimes
                ?.sort((a, b) => workTimeStartTimeAscendingSort
                  ? new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
                  : new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
                .map((workTime, index) => (
                  <WorkTime key={index} workTime={workTime} onUpdateParent={updateParent} />
                ))}
            </>
          ) : (
            <WorkShiftStart onUpdateParent={updateParent} />
          )
        )}
        {activeTab === "workshiftHistory" && (
          <WorkShiftHistory />
        )}
      </div>
    </div>
  )
}
