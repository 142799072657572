import React, { createContext, useReducer, useContext, ReactNode } from 'react';

// Define la estructura del estado de una página, como las pestañas activas.
interface PageState {
    [pageKey: string]: any; // Puede ser más específico, como { activeTab: number }
}

// Define las acciones disponibles para el PageStateContext.
type PageStateAction =
    | { type: 'SET_PAGE_STATE'; payload: { pageKey: string; state: any } }
    | { type: 'CLEAR_PAGE_STATE'; payload: { pageKey: string } };

interface PageStateContextProps {
    state: PageState;
    dispatch: React.Dispatch<PageStateAction>;
}

const PageStateContext = createContext<PageStateContextProps | undefined>(undefined);

const pageStateReducer = (state: PageState, action: PageStateAction): PageState => {
    switch (action.type) {
        case 'SET_PAGE_STATE':
            return { ...state, [action.payload.pageKey]: action.payload.state };
        case 'CLEAR_PAGE_STATE':
            const newState = { ...state };
            delete newState[action.payload.pageKey];
            return newState;
        default:
            return state;
    }
};

interface PageStateProviderProps {
    children: ReactNode;
}

export const PageStateProvider: React.FC<PageStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(pageStateReducer, {});

    return (
        <PageStateContext.Provider value={{ state, dispatch }}>
            {children}
        </PageStateContext.Provider>
    );
};

export const usePageState = (): PageStateContextProps => {
    const context = useContext(PageStateContext);
    if (!context) {
        throw new Error('usePageState must be used within a PageStateProvider');
    }
    return context;
};