import { TabPermissionType } from "../../components/common/navigation/Tabs";
import { GetCookie } from "../../services/cookie/CookieService";

export function getQueryParam(name: string, queryString: string): string | null {
  let reg = new RegExp('[?&]' + name + '=([^&#]*)', 'i');
  let string = reg.exec(queryString);
  return string ? string[1] : null;
}

interface Tab {
  key: string;
  name: string;
  icon: any;
  current: boolean;
  permission?: string[];
}

export function userHasPermission(tab: Tab, sectionKey: string) {
  const permissions = JSON.parse(GetCookie("user") || "{}").permissions;
  const userIsActive = JSON.parse(GetCookie("user") || "{}").isActive;

  // Asegúrate de que 'tab.permission' exista y sea un array antes de proceder
  if (!tab.permission || !Array.isArray(tab.permission)) {
    return true;
  } else if (tab.permission[0] === TabPermissionType.OnlyActiveUsers && userIsActive) {
    return true;
  } else if (tab.permission[0] === TabPermissionType.OnlyInactiveUsers && !userIsActive) {
    return true;
  }

  const sections = sectionKey.split("__"); // Divide en secciones basado en "__"
  let currentPermissions = permissions;

  // Recorre las secciones para acceder a la subsección correcta
  for (const section of sections) {
    currentPermissions = currentPermissions[section];
    if (!currentPermissions) {
      return false; // Retorna false si no se encuentra la subsección
    }
  }

  // Si currentPermissions es un array de roles, verifica si incluye alguno de los roles de la pestaña
  if (Array.isArray(currentPermissions)) {
    const tabPermissions = tab.permission; // Asigna a una variable local para evitar problemas de nulidad
    return currentPermissions.some(role => tabPermissions.includes(role));
  }

  return false;
}

export function getFirstTab(tabs: Tab[], sectionKey: string): string {
  const firstTabWithPermission = tabs.find(tab => userHasPermission(tab, sectionKey));
  return firstTabWithPermission ? firstTabWithPermission.key : "";
}

export function getBasePath() {
  const basePath = process.env.REACT_APP_BASE_DOMAIN;
  return basePath;
}

export function getImagePath(imagePath: string): string {
  return getBasePath() + imagePath;
}

export function generateRandomString(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}