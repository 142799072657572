import React from 'react';
import TreeNode, { TreeNodeProps } from './TreeNode';

interface TreeViewProps {
  root: {
    name: string,
    children?: TreeViewProps['root'][],
    fileUrl?: string,
  },
  searchValue: string,
}

const TreeView: React.FC<TreeViewProps> = ({ root, searchValue }) => {
  return (
    <div className="tree-view">
      {root.children && root.children.map(node => (
        <TreeNode key={node.name} node={node} searchValue={searchValue} />
      ))}
    </div>
  );
};

export default TreeView;