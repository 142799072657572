import { DefaultTFuncReturn } from 'i18next';
import React from 'react';

export interface SimpleHeaderWithTwoButtonsProps {
    title: string;
    textSize: string;
    buttonText1: string;
    buttonText2?: string | DefaultTFuncReturn;
    button1Click: () => void;
    button2Click?: () => void;
}

const SimpleHeaderWithTwoButtons: React.FC<SimpleHeaderWithTwoButtonsProps> = ({
    title,
    textSize,
    buttonText1,
    buttonText2,
    button1Click,
    button2Click,
}) => {
    return (
        <div className="flex justify-between items-center border-b border-gray-200 pb-5">
            <h3 className={`${textSize} font-semibold leading-6 text-gray-900`}>
                {title}
            </h3>
            <div className="flex items-center gap-x-6">
                <button
                    type="button"
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={button1Click}
                >
                    {buttonText1}
                </button>
                {buttonText2 &&
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={button2Click}
                    >
                        {buttonText2}
                    </button>
                }
            </div>
        </div>
    );
};

export default SimpleHeaderWithTwoButtons;