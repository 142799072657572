import { useState, useEffect, useContext } from 'react';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from "react-i18next";
import { GetYears, GetMonthsByYear, GetWorkShiftsByEmployeeByMonthByYear, PatchWorkShift, AddWorkShiftAttachment, GetWorkShiftsExportExcel, GenerateMonthSummaryForEmployee, DeleteWorkShiftAttachment } from "../../services/time-control/TimeControlService";
import Alert from '../../components/common/alert/Alert';
import Dropdown, { IDropdownBlock } from '../common/dropdown/Dropdown';
import { ArrowDownTrayIcon, PaperClipIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import Table from '../../components/common/list/Table';
import { TableColumn } from '../../interfaces/common/list/TableProps';
import { WorkShift } from '../../interfaces/time-control/WorkShift';
import Modal from '../common/overlay/Modal';
import SectionHeader from '../common/header/SectionHeader';
import { WorkTime } from '../../interfaces/time-control/WorkTime';
import { format } from 'date-fns';
import { WorkShiftAttachment } from '../../interfaces/time-control/WorkShiftAttachment';
import { useSpinner } from '../../contexts/SpinnerContext';
import { Employee } from '../../interfaces/employee/Employee';
import { GetCookie } from '../../services/cookie/CookieService';
import { TimeOff } from '../../interfaces/time-control/TimeOff';
import { Modal as AntdModal, Skeleton } from 'antd';

export default function TimeControl() {
    const { t } = useTranslation();
    const { setIsLoading } = useSpinner();
    const { showNotification } = useContext(NotificationContext);
    const [years, setYears] = useState<number[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>(0);
    const [months, setMonths] = useState<number[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<number>(0);
    const [workShifts, setWorkShifts] = useState<WorkShift[] | undefined>(undefined);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedWorkShift, setSelectedWorkShift] = useState<WorkShift | null>(null);
    const [workShiftHistoryError, setWorkShiftHistoryError] = useState<string | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);
    const [selectedAttachments, setSelectedAttachments] = useState<WorkShiftAttachment[]>([]);

    const dropdownBlocks: IDropdownBlock[] = [
        {
            items: [
                // { label: t("common.exportExcel"), icon: ArrowDownTrayIcon, action: () => exportExcel(selectedMonth, selectedYear) },
                {
                    label: t("timeControl.exportMonthCertificate"),
                    icon: ArrowDownTrayIcon,
                    action: async () => (
                        setIsLoading(true),
                        await GenerateMonthSummaryForEmployee(selectedMonth, selectedYear),
                        setIsLoading(false)
                    )
                },
            ]
        },
        // {
        //     items: [
        //         { label: t("common.reportError"), icon: PencilSquareIcon, action: () => alert('Funcionalidad en proceso de desarrollo.') },
        //     ]
        // },
    ];

    const columnsWorkShift: TableColumn<WorkShift>[] = [
        {
            dataKey: 'startShift',
            title: t("workShiftHistory.workShift"),
            format: (row: WorkShift) => format(new Date(row.startShift), 'dd/MM/yyyy'),
            className: 'px-3 py-3.5 text-center text-sm font-semibold text-gray-900',
            cellClassName: 'px-3 py-3.5 text-center text-sm font-normal text-gray-900',
            // collapsedContent: (row) => (
            //     <>
            //         <dd className="mt-1 truncate text-gray-500">{t("common.to")} {row.endShift ? format(new Date(row.endShift), 'dd/MM/yyyy HH:mm:ss') : ''}</dd>
            //     </>
            // ),
        },
        // {
        //     dataKey: 'endShift',
        //     title: t("workShiftHistory.endWorkShift"),
        //     format: (row: WorkShift) => row.endShift ? format(new Date(row.endShift), 'dd/MM/yyyy HH:mm:ss') : '',
        //     className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
        //     cellClassName: 'px-3 py-3.5 text-left text-sm font-normal text-gray-900',
        //     hideInMobile: true,
        // },
        {
            dataKey: 'totalComputedTime',
            title: t("workShiftHistory.totalComputedTime"),
            className: 'px-3 py-3.5 text-center text-sm font-semibold text-gray-900',
            cellClassName: 'px-3 py-3.5 text-center text-sm font-normal text-gray-900',
        },
    ];

    const columnsWorkTimes: TableColumn<WorkTime>[] = [
        {
            dataKey: 'startTime',
            title: 'Inicio del tramo',
            format: (row: WorkTime) => format(new Date(row.startTime), 'dd/MM/yyyy HH:mm:ss'),
            className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
            cellClassName: 'px-3 py-3.5 text-left text-sm font-normal text-gray-900',
            // collapsedContent: (row) => (
            //     <>
            //         <dd className="mt-1 truncate text-gray-500">{t("common.to")} {row.endTime ? format(new Date(row.endTime), 'dd/MM/yyyy HH:mm:ss') : ''}</dd>
            //     </>
            // ),
        },
        {
            dataKey: 'endTime',
            title: 'Final del tramo',
            format: (row: WorkTime) => row.endTime ? format(new Date(row.endTime), 'dd/MM/yyyy HH:mm:ss') : '',
            className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
            cellClassName: 'px-3 py-3.5 text-left text-sm font-normal text-gray-900',
            // hideInMobile: true,
        },
    ];

    const columnsTimeOffs: TableColumn<TimeOff>[] = [
        {
            dataKey: 'timeLogType',
            title: 'Causa',
            format: (row: TimeOff) => row.timeLogType.description,
            className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
            cellClassName: 'px-3 py-3.5 text-left text-sm font-normal text-gray-900',
        },
        {
            dataKey: 'minutesInHHMMFormat',
            title: 'Tiempo HH:MM',
            format: (row: TimeOff) => row.minutesInHHMMFormat,
            className: 'px-3 py-3.5 text-center text-sm font-semibold text-gray-900',
            cellClassName: 'px-3 py-3.5 text-center text-sm font-normal text-gray-900',
        },
    ];

    const handleRowButtonClick = (row: WorkShift) => {
        setSelectedWorkShift(row);
        setSelectedAttachments(row.attachments ?? []);
        setModalIsOpen(true);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && validateFile(file)) {
            let files: File[] = [];
            files.push(file);
            updateWorkShiftAttachments(files);
        }
    };

    const validateFile = (file: File): boolean => {
        const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'docx'];
        const maxFileSize = 10 * 1024 * 1024; // 10 MB

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (!allowedExtensions.includes(fileExtension || '')) {
            setFileError(t('uploadFiles.attachDocumentModalInvalidExtensionError'));
            return false;
        }

        if (file.size > maxFileSize) {
            setFileError(t('uploadFiles.attachDocumentModalMaxSizeError'));
            return false;
        }

        setFileError(null);
        return true;
    };

    const handleSaveClick = () => {
        const fetchUpdateWorkShiftEmployeeRemarks = async () => {
            if (selectedWorkShift !== null) {
                setIsLoading(true);

                // Asumiendo que updatedWorkShift ya contiene los adjuntos existentes (si los hay)
                const updatedWorkShift: WorkShift | null = await PatchWorkShift(selectedWorkShift.id, [
                    { fieldName: "employeeRemarks", fieldValue: selectedWorkShift.employeeRemarks },
                ]) ?? selectedWorkShift;

                if (selectedAttachments && selectedAttachments.length > 0) {
                    // Filtramos para obtener solo los nuevos adjuntos (id === 0)
                    const newAttachmentPromises = selectedAttachments
                        .filter(selectedAttachment => selectedAttachment.id === 0)
                        .map(async selectedAttachment => {
                            return AddWorkShiftAttachment(selectedWorkShift.id, selectedAttachment.attachment!);
                        });

                    // Esperamos a que todas las promesas de nuevos adjuntos se resuelvan
                    const newAttachments = (await Promise.all(newAttachmentPromises))
                        .filter((attachment): attachment is WorkShiftAttachment => attachment !== null);

                    // Combinar los adjuntos existentes (si los hay) con los nuevos
                    const combinedAttachments = [...(updatedWorkShift?.attachments ?? []), ...newAttachments];

                    if (updatedWorkShift) {
                        // Actualizamos el turno de trabajo actualizado con la lista combinada de adjuntos
                        updatedWorkShift.attachments = combinedAttachments;
                        // Y también actualizamos el estado de selectedAttachments con esta nueva lista combinada
                        setSelectedAttachments(combinedAttachments);
                    }
                }

                // Finalmente, actualizamos el estado de selectedWorkShift y workShifts con los cambios realizados
                setSelectedWorkShift(updatedWorkShift);
                setWorkShifts(workShifts => workShifts!.map(workShift =>
                    workShift.id === updatedWorkShift?.id ? updatedWorkShift : workShift
                ));

                setIsLoading(false);
            }
        };

        fetchUpdateWorkShiftEmployeeRemarks();
        setModalIsOpen(false);
    };

    const updateWorkShiftAttachments = (selectedFiles: File[]) => {
        if (selectedFiles.length > 0) {
            const newAttachments = selectedFiles.map((file, index) => {
                const attachment: WorkShiftAttachment = {
                    id: index,
                    fileName: file.name,
                    fileType: file.type,
                    attachment: file
                };
                return attachment;
            });

            setSelectedAttachments(prevAttachments => [...prevAttachments ?? [], ...newAttachments]);
        }
    };

    const exportExcel = async (selectedMonth: number, selectedYear: number) => {
        setIsLoading(true);
        const employee = JSON.parse(GetCookie("employee") || "{}") as Employee;
        await GetWorkShiftsExportExcel(`${t("timeControl.list")} ${selectedMonth}-${selectedYear}`, {
            EmployeeId: employee.id,
            _Month: selectedMonth,
            _Year: selectedYear,
            SortCriteria: [
                {
                    Field: "StartShift",
                    Order: "asc"
                }
            ]
        });
        setIsLoading(false);
    };

    useEffect(() => {
        const fetchYears = async () => {
            const result = await GetYears();
            if (result && result.length > 0) {
                setYears(result);
            } else {
                setIsLoading(false);
            }
        };

        fetchYears();
    }, []);

    useEffect(() => {
        if (years !== undefined) {
            setSelectedYear(years[0]);
        }
    }, [years]);

    useEffect(() => {
        if (selectedYear !== undefined) {
            const fetchMonthsByYear = async () => {
                const result = await GetMonthsByYear(selectedYear);
                setMonths(result);
            };

            fetchMonthsByYear();
        }
    }, [selectedYear]);

    useEffect(() => {
        if (months !== undefined && months.length > 0) {
            setSelectedMonth(months[months.length - 1]);
        }
    }, [months]);

    useEffect(() => {
        if (selectedMonth !== undefined && selectedMonth > 0) {
            let timeout = setTimeout(() => {
                setWorkShifts(undefined);
            }, 500);

            const fetchWorkShiftsByEmployeeByMonthByYear = async () => {
                await GetWorkShiftsByEmployeeByMonthByYear(selectedMonth, selectedYear).then((result) => {
                    clearTimeout(timeout);
                    setWorkShifts(result || []);
                });
            };

            fetchWorkShiftsByEmployeeByMonthByYear();
        }
    }, [selectedMonth]);

    return (
        <>
            {workShiftHistoryError && <Alert text={workShiftHistoryError} type="warning" />}
            <h3 className="mt-6 mb-2 text-lg font-medium leading-6 text-gray-900">
                {t("workShiftHistory.title")}
            </h3>
            {workShifts && workShifts.length > 0 ? (
                <>
                    <div className="mt-4 flex justify-between">
                        <div className="flex space-x-4">
                            <div className="sm:col-span-4">
                                <div className="mt-2">
                                    <select
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                                    >
                                        {years.map((year, index) => (
                                            <option key={index} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-4">
                                <div className="mt-2">
                                    <select
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                    >
                                        {months.map((month, index) => (
                                            <option key={index} value={month}>
                                                {t(`common.month${month.toString()}`)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <div className="mt-2">
                                <Dropdown title={t("common.options")} blocks={dropdownBlocks} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            {workShifts !== null &&
                                <>
                                    <Table<WorkShift> data={workShifts} columns={columnsWorkShift} onRowButtonClick={handleRowButtonClick} rowButtonText={t("common.viewDetail") || ""} />
                                    <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                                        <div>
                                            <SectionHeader title={`${t("workShiftHistory.workShift")} ${selectedWorkShift ? format(new Date(selectedWorkShift.startShift), 'dd/MM/yyyy') : ''}`} textSize="text-xl" />
                                            <div className="space-y-8">
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-3">
                                                    <h3 className="mt-6 text-lg font-medium leading-6 text-gray-900">
                                                        {t("workShiftHistory.workTime")}
                                                    </h3>
                                                    <Table<WorkTime> data={selectedWorkShift?.workTimes || []} columns={columnsWorkTimes} marginTop={0} />
                                                </div>
                                                {selectedWorkShift?.timeOffs && selectedWorkShift.timeOffs.length > 0 &&
                                                    <div className="grid grid-cols-1 gap-x-8 gap-y-3">
                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                                                            {t("workShiftHistory.timeOff")}
                                                        </h3>
                                                        <Table<TimeOff> data={selectedWorkShift?.timeOffs || []} columns={columnsTimeOffs} marginTop={0} />
                                                    </div>
                                                }
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-3">
                                                    <div className="col-span-12 mt-0">
                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                                                            {t("workShiftHistory.attachments")}
                                                        </h3>
                                                        {selectedAttachments && selectedAttachments.length > 0 ? (
                                                            <div className="rounded-md">
                                                                <dl className="grid grid-cols-1 sm:grid-cols-2">
                                                                    <div className="px-0 py-4 sm:col-span-2">
                                                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                                                            {t("uploadFiles.selectedFiles")}
                                                                        </dt>
                                                                        <dd className="mt-4 text-sm text-gray-900">
                                                                            <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                                                                {selectedAttachments.map((attachment, index) => (
                                                                                    <li key={index} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                                                        <div className="flex w-0 flex-1 items-center space-x-4">
                                                                                            <button
                                                                                                className="rounded-md bg-red-50 px-3 md:px-2 py-2 md:py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                                                                                onClick={() => {
                                                                                                    AntdModal.confirm({
                                                                                                        title: t("timeControl.deleteWorkShiftAttachmentTitle").toString(),
                                                                                                        content: <div className="text-justify py-3" dangerouslySetInnerHTML={{ __html: `${t("timeControl.deleteWorkShiftAttachmentSubtitle", { attachmentName: attachment.fileName })}` }} />,
                                                                                                        okText: t("common.accept").toString(),
                                                                                                        okType: 'danger',
                                                                                                        cancelText: t("common.cancel").toString(),
                                                                                                        centered: true,
                                                                                                        width: 550,
                                                                                                        onOk() {
                                                                                                            if (attachment.id > 0) {
                                                                                                                setIsLoading(true);
                                                                                                                DeleteWorkShiftAttachment(selectedWorkShift!.id, attachment.id)
                                                                                                                    .then((result) => {
                                                                                                                        if (result === true) {
                                                                                                                            setSelectedAttachments(prevSelectedAttachments => {
                                                                                                                                if (prevSelectedAttachments) {
                                                                                                                                    const updatedAttachments = prevSelectedAttachments.filter(att => att.id !== attachment.id);
                                                                                                                                    return updatedAttachments;
                                                                                                                                }
                                                                                                                                return prevSelectedAttachments;
                                                                                                                            });
                                                                                                                            setWorkShifts(prevWorkShifts => prevWorkShifts?.map(workShift => {
                                                                                                                                if (workShift.id === selectedWorkShift!.id) {
                                                                                                                                    const updatedAttachments = workShift.attachments?.filter(att => att.id !== attachment.id) || [];
                                                                                                                                    return { ...workShift, attachments: updatedAttachments };
                                                                                                                                }
                                                                                                                                return workShift;
                                                                                                                            }));
                                                                                                                            AntdModal.success({
                                                                                                                                title: t("timeControl.deleteWorkShiftAttachmentSuccess").toString(),
                                                                                                                                content: "",
                                                                                                                                okText: t("common.accept").toString(),
                                                                                                                                centered: true
                                                                                                                            })
                                                                                                                        }
                                                                                                                        setIsLoading(false);
                                                                                                                    });
                                                                                                            } else {
                                                                                                                setSelectedAttachments(prevSelectedAttachments => {
                                                                                                                    if (prevSelectedAttachments) {
                                                                                                                        const updatedAttachments = prevSelectedAttachments.filter(att => att.id !== attachment.id);
                                                                                                                        return updatedAttachments;
                                                                                                                    }
                                                                                                                    return prevSelectedAttachments;
                                                                                                                });
                                                                                                                AntdModal.success({
                                                                                                                    title: t("timeControl.deleteWorkShiftAttachmentSuccess").toString(),
                                                                                                                    content: "",
                                                                                                                    okText: t("common.accept").toString(),
                                                                                                                    centered: true
                                                                                                                })
                                                                                                            }
                                                                                                        },
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </button>
                                                                                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                                                                <span className="truncate font-medium">{attachment.fileName}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                        ) : (
                                                            <label htmlFor="comment" className="mt-5 mb-6 block text-sm font-medium leading-6 text-gray-900">
                                                                {t("uploadFiles.noFilesSelected")}
                                                            </label>
                                                        )}
                                                        <div className="flex items-center justify-end gap-x-6 mt-1">
                                                            <label htmlFor="file-upload" className="rounded-md bg-indigo-500 px-3 py-3 md:py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                                                                {t("uploadFiles.attachDocument")}
                                                                <input
                                                                    id="file-upload"
                                                                    name="file-upload"
                                                                    type="file"
                                                                    onChange={handleFileChange}
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </label>
                                                        </div>
                                                        {fileError && <Alert text={fileError} type="error" />}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-10">
                                                    <div className="col-span-12">
                                                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                                            {t("workShiftHistory.employeeRemarks")}
                                                        </label>
                                                        <div className="mt-2">
                                                            <textarea
                                                                rows={4}
                                                                name="comment"
                                                                id="comment"
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                defaultValue={selectedWorkShift?.employeeRemarks}
                                                                onChange={(event) => {
                                                                    setSelectedWorkShift(prevWorkShift => {
                                                                        const updatedWorkShift = {
                                                                            ...prevWorkShift!,
                                                                            employeeRemarks: event.target.value
                                                                        };
                                                                        return updatedWorkShift;
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end gap-x-6">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-gray-500 px-3 py-3 md:py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                                                        onClick={() => setModalIsOpen(false)}
                                                    >
                                                        {t("common.cancel")}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-indigo-500 px-3 py-3 md:py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                        onClick={handleSaveClick}
                                                    >
                                                        {t("common.save")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </>
                            }
                        </div>
                    </div>
                </>
            ) : workShifts && workShifts.length === 0 ? (
                <>
                    <div className="mt-4 flex justify-between">
                        {months.length > 0 &&
                            <>
                                <div className="flex space-x-4">
                                    <div className="sm:col-span-4">
                                        <div className="mt-2">
                                            <select
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                                            >
                                                {years.map((year, index) => (
                                                    <option key={index} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <div className="mt-2">
                                            <select
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                            >
                                                {months.map((month, index) => (
                                                    <option key={index} value={month}>
                                                        {t(`common.month${month.toString()}`)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="mt-2">
                                        <Dropdown title={t("common.options")} blocks={dropdownBlocks} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <Alert text={t("workShiftHistory.noWorkShift")} type="info" />
                </>
            ) : workShifts === undefined ? (
                <Skeleton className="mt-8" active paragraph={{ rows: 14 }} />
            ) : null}
        </>
    )
}